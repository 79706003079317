<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="updateGameServiceCategory" tag="div">
      <b-card v-if="gameServiceCategoryDetail" class="data-edit-wrapper">
        <h2>Game Service Category Details</h2>

        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameServiceCategoryDetail.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Sub Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameServiceCategoryDetail.subTitle"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="gameServiceCategories" md="4">
              <b-form-group
                  label="Parent"
              >

                <v-select
                    v-model="gameServiceCategoryDetail.parentId"
                    :options="filteredGameServiceCategories"
                    :reduce="title => title.id"
                    dir="ltr"

                    label="title"

                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                  label="Short Description"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameServiceCategoryDetail.shortDescription"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Main Picture"
              >
                <div class="">
                  <div
                      class=" position-relative   border rounded  shadow     "
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="gameServiceCategoryDetail.imageUrl" alt=""
                         style="object-fit: contain;height: 10rem;width: 10rem"
                    >
                  </div>

                </div>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Upload New Picture"
              >
                <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >
                <ckEditorMain ref="editorContent" :defaultContent="gameServiceCategoryDetail.fullDescription"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
          </b-row>
        </b-form>

      </b-card>
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          centered
          ok-title="Delete"
          title="Delete Game Service"
          @ok.prevent="deleteGameService"
      >
        <div class="row">
          <div class="col-md-12">
            <span>Are you sure you want to delete this game service ?</span>
          </div>
        </div>
      </b-modal>
      <b-card v-if="gameServiceCategoryDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="gameServiceCategoryDetail.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="gameServiceCategoryDetail.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pl-1">
            <div class="col-md-12 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ gameServiceCategoryDetail.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/[game-name]/<span class="serp-arrow"></span></span>
                    <span v-if="gameServiceCategoryDetail.seoUrl" style="overflow-wrap: anywhere">
                      {{ gameServiceCategoryDetail.seoUrl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ gameServiceCategoryDetail.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ gameServiceCategoryDetail.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ gameServiceCategoryDetail.seoUrl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/[game-name]/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ gameServiceCategoryDetail.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
                @click="updateGameServiceCategory"
            >
              <span class="mr-25 align-middle">Submit</span>

            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col cols="12" md="12">
            <h2>Game Services</h2>
          </b-col>
          <b-col class="my-1  d-flex align-items-center" cols="12" md="1">
            <b-button
                class="btn-tour-skip mr-1 mt-1"
                variant="primary"
            >
              <b-link
                  v-if="gameServiceCategoryDetail"
                  :to="`/apps/games/game-service-categories/game-service/create-game-service?gameId=${$route.query.gameId}&gameServiceCategoryId=${gameServiceCategoryDetail.id}`"
              >
                <span class="mr-25 align-middle text-white">Create</span>
              </b-link>

            </b-button>
          </b-col>
          <b-col class="my-1" cols="12" md="3">
            <b-form-group label="Search By">
              <debouncer @setValue="getsearchValue"></debouncer>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="gameServices"
                :sort-by.sync="sortBy"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(imageUrl)="data">
                <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <b-link
                      :to="`/apps/games/game-service-categories/game-service/manage-game-services?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${data.item.gameServiceId}`"
                  >
                    <feather-icon
                        class="text-primary"
                        icon="EditIcon"
                        size="20"
                    />
                  </b-link>
                  <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedGameService(data.item)">
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <b-col cols="12">
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                    class="d-flex align-items-center justify-content-center"
                    cols="12"
                    sm="12"
                >
                  <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                </b-col>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    cols="12"
                    sm="6"
                >
                  <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalCount"
                      class="mb-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>

    </validation-observer>

  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetGameServiceCategoryDetail, UpdateGameServiceCategory } from '@/libs/Api/gameServiceCategory'
import { GetGameServiceCategoriesByGameId } from '@/libs/Api/games'
import { DeleteGameService, GetGameServicesByGameServiceCategoryId } from '@/libs/Api/gameService'
import Debouncer from '@/views/components/debouncer.vue'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    Debouncer,
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-game-service-category',
  title: 'manage game service category',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      currentPage: 1,
      perPage: 10,
      totalCount: null,
      sortBy: '',
      gameServiceCategories: null,
      searchQuery: '',
      selectedGameService: null,
      gameServices: null,
      gameServiceCategoryDetail: null,
      myTableColumns: [
        {
          key: 'gameServiceId',
          label: '#',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: true
        },
      ],

    }
  },
  watch: {
    currentPage: function (val) {
      this.getGameServicesByGameServiceCategoryId()
    }
  },
  computed: {
    filteredGameServiceCategories(){
      return this.gameServiceCategories.filter(e=> e.id !== +this.$route.query.gameServiceCategoryId)
    },
    allCharactersToDash: {
      get: function () {
        return this.gameServiceCategoryDetail.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.gameServiceCategoryDetail.seoUrl = newValue
          // Remove all characters that are NOT number
          this.gameServiceCategoryDetail.seoUrl = this.gameServiceCategoryDetail.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.gameServiceCategoryDetail.seoUrl = this.gameServiceCategoryDetail?.seoUrl?.replaceAll(' ', '-')
        } else if (!newValue || this.gameServiceCategoryDetail.seoUrl === '') {
          this.gameServiceCategoryDetail.seoUrl = null
        }
      },
    },
  },
  async created() {
    await Promise.all([
      this.getGameServiceCategoryDetail(),
      this.getGameServicesByGameServiceCategoryId(),
      this.getGameServiceCategories()
    ])
  },
  methods: {
    getsearchValue(query) {
      this.searchQuery = query
      this.getGameServicesByGameServiceCategoryId()
    },
    setSelectedGameService(item) {
      this.selectedGameService = JSON.parse(JSON.stringify(item))
    },
    async updateGameServiceCategory() {
      let _this = this
      Helper.validateForm(_this, 'updateGameServiceCategory', async () => {
        _this.showOverlay = true
        let updateGameServiceCategory = new UpdateGameServiceCategory(_this)
        this.$refs.mediaHandler.sendFiles()
        updateGameServiceCategory.setQueryParams({
          gameServiceCategoryId: _this.$route.query.gameServiceCategoryId,
          gameId: _this.$route.query.gameId,
          parentId: _this.gameServiceCategoryDetail.parentId ? _this.gameServiceCategoryDetail.parentId : ''
        })
        let myForm = await Helper.makeFormData(_this.gameServiceCategoryDetail)
        updateGameServiceCategory.setParams(myForm)
        await updateGameServiceCategory.sendFormData(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.getGameServiceCategoryDetail()
            _this.$refs.mediaHandler.clearMedia()

          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }

        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },
    async getGameServiceCategories() {
      let _this = this
      _this.showOverlay = true

      let getGameServiceCategoriesByGameId = new GetGameServiceCategoriesByGameId(_this)
      let data = {
        PageNumber: 1,
        count: 200
      }
      getGameServiceCategoriesByGameId.setPartialUrl({ id: _this.$route.query.gameId })
      getGameServiceCategoriesByGameId.setParams(data)
      await getGameServiceCategoriesByGameId.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServiceCategories = content.data.data

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getGameServicesByGameServiceCategoryId() {
      let _this = this
      _this.showOverlay = true

      let getGameServicesByGameServiceCategoryId = new GetGameServicesByGameServiceCategoryId(_this)
      getGameServicesByGameServiceCategoryId.setQuery({
        search: _this.searchQuery,
        pageNumber: _this.currentPage,
        count: _this.perPage,
        gameServiceCategoryId: _this.$route.query.gameServiceCategoryId
      })
      await getGameServicesByGameServiceCategoryId.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServices = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    setFiles(data) {
      if (data) {
        this.gameServiceCategoryDetail['imageFile'] = data
      } else {
        this.gameServiceCategoryDetail['imageFile'] = null
      }
    },
    async deleteGameService() {
      let _this = this
      _this.showOverlay = true
      let deleteGameService = new DeleteGameService(_this)
      deleteGameService.setParams({ gameServiceId: _this.selectedGameService.gameServiceId })
      await deleteGameService.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getGameServicesByGameServiceCategoryId()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    setEditorContent(content) {
      this.gameServiceCategoryDetail.fullDescription = content
    },
    async getGameServiceCategoryDetail() {
      let _this = this
      _this.showOverlay = true
      let getGameServiceCategoryDetail = new GetGameServiceCategoryDetail(_this)
      getGameServiceCategoryDetail.setPartail({ id: _this.$route.query.gameServiceCategoryId })
      await getGameServiceCategoryDetail.sendFormData(function (content) {
        _this.showOverlay = false
        _this.gameServiceCategoryDetail = content.data

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

  }
}
</script>

<style scoped>

</style>
